.rb-tabs-active-item {
    background-color: #8B54F7 !important;
}

.rb-tabs-items {
    color: #3F444D;
}

.rb-tabs-items .active {
    color: #8B54F7 !important;
}

@media (max-width: 1023px) {
    .rb-tabs-items {
        justify-content: flex-start !important;
    }
}