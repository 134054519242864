.rb-tabs-active-item {
    background-color: #0B8CBA !important;
}

.rb-tabs-items {
    color: #3F444D;
    font-weight: 600;
    font-size: 14px;
}

.rb-tabs-items .active {
    color: #0B8CBA !important;
    font-weight: 600;
    font-size: 14px;
}

@media (max-width: 1023px) {
    .rb-tabs-items {
        justify-content: flex-start !important;
    }
}